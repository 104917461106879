import logo from './FarlingtonLights_Logo_WhiteBG.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Down For Maintenance!!!!
        </p>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          OH NO, Looks like the light have taken so much power they have crashed the Server
        </p>
        <p>
          Dont worry our Head IT Elf is on the case.
        </p>
        <p>Looking for our donation page?</p>
        <a
          className="App-link"
          href="https://www.justgiving.com/crowdfunding/farlingtonlight2024"
          target="_blank"
          rel="noopener noreferrer"
        >
          JustGiving
        </a>
      </header>
    </div>
  );
}

export default App;
